import React from 'react'
import { Typography, Box, Grid, Divider, useTheme, Paper, Button } from '@mui/material'

const SubSection = () => {
    return (
        <>
            <Box sx={{
                ml: { xs: 2, sm: 3, md: 15, lg: 19, xl: 23 }, mr: { xs: 2, sm: 3, md: 15, lg: 19, xl: 23 },
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center'
            }}>

                <Paper sx={{ width: '80%', height: '60%', backgroundColor: 'info.main' }}>
                    <Box mt={3}>
                        <Button   variant="contained">Subscribe</Button>
                        <Box mt={3} p={1}>
                            <Typography  variant='h6' sx={{  fontStyle: 'italic' }}style={{ fontFamily: "'Crimson Text', sans-serif" }}>
                                Live in Sweden?   Subscribe now to unlock exclusive deals and discounts from Swedish businesses,
                                only available for our community.
                            </Typography>
                        </Box>
                    </Box>
                </Paper>

            </Box>

        </>
    )
}

export default SubSection
