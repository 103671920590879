import React from 'react'
import { Typography, Box, Grid, Divider } from '@mui/material'
import SubSection from '../components/SubSection'

const Life = () => {
  return (
    <>
      <Box sx={{ mt: { xs: 11, sm: 11, md: 21, lg: 21, xl: 21 }, ml: { xs: 2, sm: 3, md: 15, lg: 19, xl: 23 }, mr: { xs: 2, sm: 3, md: 15, lg: 19, xl: 23 } }}>
        <Box >
          <Typography variant='h1' align='center' sx={{ fontFamily: 'Permanent Marker', fontSize: { xs: '40px', md: '50px', lg: '60px' } }}>
            Life In Sweden
          </Typography>
        </Box>
        <Box >
          <Divider sx={{ bgcolor: 'secondary.main', padding: "2px" }} />
        </Box >
        <Box mt={4} p={4}>
          <Typography variant='h6' sx={{ textAlign: 'left', fontWeight: 'bold', fontSize: { xs: '18px', sm: '18px', md: '23px' } }}>

          "In this section, I provide every essential and key information that we need for a better life in Sweden. 
          From savvy money-saving tips to charming Swedish pick-up lines, I've got you covered on all fronts.

          </Typography>
        </Box >
        <Box mt={4} mb={3} p={3} sx={{ backgroundColor: 'info.dark' }}>
          <SubSection />
        </Box>
      </Box>
    </>
  )
}

export default Life
