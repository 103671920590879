import React from 'react';
import { ThemeProvider, createTheme, CssBaseline, Box } from "@mui/material";
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Subscribe from "./pages/Subscribe";
import Blogs from "./pages/Blogs";
import Life from "./pages/Life";
import Review from "./pages/Review";
import Travel from "./pages/Travel";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Home from './pages/Home';
import BestTimeTovisitSweden from './pages/travel/BestTimeTovisitSweden';


function App() {
  const theme = createTheme({
    palette: {
      primary: {
        main: '#006aa9'
      },
      secondary: {
        main: '#fccc04'//'#7cc8f5'
      },
      info: {
        main: '#FFFFFF',
        dark: '#eeeee4'
      }
    },
    typography: {

      fontFamily: "'poppins', sans-serif",
      /* fontSize: 14,
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,

      h1: {
  
fontFamily: "'poppins', sans-serif",
fontWeight: 600,
fontSize: "1.87rem",
lineHeight: 1,
letterSpacing: "-0.01562em",
      } */

      h3: { 
       
        letterSpacing: "0",
    },
    body1:{
      lineHeight: "1.8rem",
      fontWeight: 400,
    },
    body2:{
      fontWeightLight: 300,
    }
  }

  })

  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          <Header />
          <Routes>
            <Route path="/" exact element={<Home />} />
            <Route path="/blogs" element={<Blogs />} />
            <Route path="/lifeStyle" element={<Life />} />
            <Route path="/review" element={<Review />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/travel" element={<Travel />} />
            <Route path="/subscribe" element={<Subscribe />} />
            <Route path="/best-time-to-visit-sweden" element={<BestTimeTovisitSweden />} />
          </Routes>
          <Box>
            <Footer />
          </Box>
        </Router>


      </ThemeProvider>
    </>
  );
}

export default App;
