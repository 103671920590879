import React from 'react'
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { IconButton } from '@mui/material'

const LinkedinShareButton = (props) => {
   const handleClick = () => {
        const shareUrl = encodeURIComponent(`https://thevikingvoyages.com/${props.name}`);
        window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${shareUrl}`, 'width=600,height=400,resizable=yes,scrollbars=yes,status=yes');
      };

  return (
    <>
      <IconButton color="primary">
                <LinkedInIcon fontSize='medium' onClick={handleClick}/>
            </IconButton>
    </>
  )
}

export default LinkedinShareButton
