import { AppBar, Tabs, Tab, Button, Grid, Toolbar, useTheme, useMediaQuery, Typography, Box } from '@mui/material'
import React from 'react'
import logo from "./../assets/The-Viking-voyages-blue-logo.png";
import MiddleDrawer from './MiddleDrawer';
import { Routes, Route, Link, useLocation, Navigate } from 'react-router-dom';






export default function Header() {

    const theme = useTheme();

    const smallScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [value, setValue] = React.useState('/');
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const bigContent = (
        <AppBar position="fixed" color='info'>
            <Toolbar >
                <Grid container alignItems={'center'} justifyContent={'center'} style={{  textAlign: 'center' }}>
                    <Grid sm={3} md={5} lg={5} style={{ textAlign: 'left' }}>
                        <Link to={"/"}>
                            <img
                                src={logo}
                                alt={logo}
                                style={{ width: '275px', height: '150px' }}

                            />
                        </Link>

                    </Grid>
                    <Grid item sm={5} md={5} lg={5} >
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            textColor="primary"
                            indicatorColor="secondary"
                            aria-label="secondary tabs example"
                        >
                            <Tab value="/travel" label="Travel" component={Link} to="/travel" />
                            <Tab value="/lifeStyle" label="Life Style" component={Link} to="/lifeStyle" />
                            <Tab value="/review" label="Reviews" component={Link} to="/review" />
                            <Tab value="/blogs" label="Blogs" component={Link} to="/blogs" />
                        </Tabs>

                    </Grid>
                    <Grid item sm={2} md={2} lg={2}  >
                        <Link to={'/subscribe'}>
                            <Button variant="contained"
                                color='primary'
                                textColor='info'
                            >Subscribe
                            </Button>
                        </Link>
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
    )

    const smallContent = (
        <AppBar position="fixed" color='info'>
            <Toolbar >

                <Grid container alignItems={'center'} justifyContent={'center'} style={{ textAlign: 'center' }}>

                    <Grid item xs={10} pl={3}>
                        <img
                            src={logo}
                            alt={logo}
                            style={{ width: '125px', height: '75px' }}

                        />
                    </Grid>

                    <Grid item xs={2} >
                        <MiddleDrawer />
                    </Grid>

                    {/* <Grid item xs={3} >
            <Button  size= 'small' variant="contained"
              color='primary'
              textColor='info'
            >Subscribe
            </Button>
          </Grid> */}
                </Grid>
            </Toolbar>
        </AppBar>
    )
    return (
        <>
            <div>
                {smallScreen ? smallContent : bigContent}
            </div>


        </>

    )
}

