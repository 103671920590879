import React from 'react'
import { Typography, Box, Grid } from '@mui/material'
import SubscribtionSection from '../components/SubscribtionSection'

const Subscribe = () => {
    return (
        <>
            <Box sx={{ minHeight: '35vh', bgcolor: 'info.dark', padding: 2, mt:{xs: 9, sm: 11, md: 20, lg: 18, xl: 18} }}>
                <Box sx={{  ml: {   sm: 11, md: 15, lg: 19, xl: 23 }, mr: {  sm: 11, md: 15, lg: 19, xl: 23 }, textAlign: 'center', backgroundColor: 'info.dark' }}>
                    <Typography variant='h4' align='center' style={{ marginTop: '2em', fontFamily:'Permanent Marker'}}>
                        Subscribe Now
                    </Typography>
                    <Box>
                        <SubscribtionSection />
                    </Box>

                </Box>
            </Box>

        </>
    )
}

export default Subscribe
