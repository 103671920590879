import React from 'react'
import { Typography, Box, Grid, Divider } from '@mui/material';
import HeroSection from '../components/HeroSection';
import Subscribe from './Subscribe';
import HomeTitleSection from '../components/HomeTitleSection';
import SubscribtionSection from '../components/SubscribtionSection';
import SubSection from '../components/SubSection';
import CommonCard from '../components/CommonCard';
import image from './travel/images/best_time_visit_sweden_title.jpg'
import { Link } from 'react-router-dom';


const Home = () => {
    const bestTime = {
        "titel" : "The Best Time to Travel to Sweden: A Seasonal Guide",
        "image" : "./travel/images/best_time_visit_sweden_title.jpg"
    }
    return (
        <Box sx={{ m: { xs: 1, sm: 3, md: 15, lg: 21, xl: 25 }, textAlign: 'center' }}>
            <Box>
                <HeroSection />
            </Box>
            <Box mt={4}>
                <HomeTitleSection />
            </Box>
            <Box mt={4}>

                <Typography variant='h3' align='center' sx={{ fontFamily: 'Permanent Marker', fontSize: { xs: '23px', sm: '26px', md: '40px' } }}>
                    Recent Posts
                </Typography>
                <Divider sx={{ minHeight: '.2vh', bgcolor: 'secondary.main' }} />
                <Box mt={2} style={{textDecoration:'none'}}>
                    <Link style={{ textDecoration: 'none' }} to='/best-time-to-visit-sweden'>
                    <CommonCard titel={bestTime.titel} image={image}/>
                    </Link>
                    
                </Box>

            </Box>
            <Box mt={4} mb={3} p={3} sx={{ backgroundColor: 'info.dark' }}>
                <SubSection />
            </Box>

        </Box >
    )
}

export default Home
