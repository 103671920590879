import React from 'react'
import { Typography, Box, Grid, Divider } from '@mui/material'
import HeroData from './../data/heroData.json'
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { Button, CardActionArea, CardActions, Card } from '@mui/material';
import CommonCard from './CommonCard';

const HeroSection = () => {
    return (
        <>
            <Box sx={{ textAlign: 'center', marginTop: { xs: 15, sm: 19, md: 27, lg: 23, xl: 25 } }}>
                <Typography variant='h1' align='center' sx={{ fontFamily: 'Permanent Marker', fontSize: { xs: '40px', md: '50px', lg: '60px' } }}>
                    Explore Sweden Here
                </Typography>
            </Box>
            <Box >
                <Divider sx={{ bgcolor: 'secondary.main', padding: "2px" }} />
            </Box >
            <Box sx={{ textAlign: 'center' }}>
                <Grid container alignItems={'center'} spacing={1} mt={4}>
                    {HeroData.map((blog, i) => (
                        <Grid item xs={12} sm={6} md={4} lg={4} xl={4} Key={blog.id} sx={{ mt: 2 }}>
                            <CommonCard titel={blog.titel} image={blog.image} />
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </>
    )
}

export default HeroSection
