import React from 'react'
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import { IconButton } from '@mui/material'

const FacebookShareButton = (props) => {
   const handleClick = () => {
        const shareUrl = encodeURIComponent(`https://www.thevikingvoyages/${props.name}`);
        window.open(`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`, 'Share', 'width=600,height=400,resizable=yes,scrollbars=yes,status=yes');
      };
    return (
        <>
            <IconButton color="primary">
                <FacebookOutlinedIcon fontSize='mediun' onClick={handleClick} />
            </IconButton>
        </>
    )
}

export default FacebookShareButton
