import React from 'react'
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { Typography,Button, CardActionArea, CardActions, Card, Box, useTheme, useMediaQuery} from '@mui/material';

const CommonCard = (props) => {
    return (
        <Box >
            <Card sx={{ maxWidth: 400 }}>
                <CardActionArea>
                    <CardMedia
                        component="img"
                        height="240"
                        maxWidth="350"
                        image={props.image}
                        alt="green iguana"
                    />
                    <CardContent sx={{ bgcolor: 'info.main' }}>
                        <Typography gutterBottom variant="h6" component="div">
                            {props.titel}
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
        </Box>
    )
}

export default CommonCard