import React from 'react'
import { Typography, Box, Grid, Divider } from '@mui/material'
import SubscribtionSection from '../components/SubscribtionSection'
import SubSection from '../components/SubSection'
import CommonCard from '../components/CommonCard'
import {Link} from 'react-router-dom'
import image from './../pages/travel/images/best_time_visit_sweden_title.jpg'


const Travel = () => {
  return (
    <>
      <Box sx={{ mt: { xs: 11, sm: 11, md: 21, lg: 21, xl: 21 }, ml: { xs: 2, sm: 3, md: 15, lg: 19, xl: 23 }, mr: { xs: 2, sm: 3, md: 15, lg: 19, xl: 23 } }}>
        <Box >
          <Typography variant='h1' align='center' sx={{ fontFamily: 'Permanent Marker', fontSize: { xs: '40px', md: '50px', lg: '60px' } }}>
            Travel In Sweden
          </Typography>
        </Box>
        <Box >
          <Divider sx={{ bgcolor: 'secondary.main', padding: "2px" }} />
        </Box >
        <Box mt={4} p={4}>
          <Typography variant='h6' sx={{ textAlign: 'left', fontWeight: 'bold', fontSize: { xs: '18px', sm: '18px', md: '23px' } }}>

            "Sweden offers a captivating blend of natural beauty, vibrant cities,
            and rich cultural experiences, making it an enticing destination for travelers.
          </Typography>
          <br />
          <Typography variant='h6' sx={{ textAlign: 'left', fontWeight: 'bold', fontSize: { xs: '18px', sm: '18px', md: '23px' } }}>
            Here's a glimpse into what Sweden has to offer:"

          </Typography>
        </Box >
        <Box mt={4}>
          <Box mt={2} style={{ textDecoration: 'none' }}>
            <Link style={{ textDecoration: 'none' }} to='/best-time-to-visit-sweden'>
              <CommonCard titel="The Best Time to Travel to Sweden: A Seasonal Guide" image={image} />
            </Link>

          </Box>

        </Box>
        <Box mt={4} mb={3} p={3} sx={{ backgroundColor: 'info.dark' }}>
          <SubSection />
        </Box>

      </Box>

    </>
  )
}

export default Travel
