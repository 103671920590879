import React from 'react'
import { Box, Grid, Typography, TextField, Button, Paper } from '@mui/material'

const SubscribtionSection = () => {
    return (
        <>
            <Box >
                <Grid container>
                    <Grid item xs={12} sm={12} md={6} mt={3} >
                    <Box sx={{ width: '100%', pr: { md:3, lg: 20, xl: 20} }}>
                        <Typography style={{ fontWeight: 'bold', fontStyle: 'italic' }}>
                            Live in Sweden? Subscribe now to unlock exclusive deals and discounts from local Swedish businesses!
                            Join our community to access special deals available only to our members.
                        </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} mt={3}>
                        <Paper sx={{ backgroundColor: 'info.main', p: 2, ml: {md:1, lg: 20, xl: 20} }}>
                            <Box sx={{ width: '100%', mt: 1 }}>
                                <TextField label="Name" variant="standard" />
                            </Box>
                            <Box sx={{ width: '100%', mt: 1 }}>
                                <TextField label="Email" variant="standard" />
                            </Box>
                            <Box sx={{ width: '100%', mt: 5 }}>
                                <Button variant="contained"
                                    color='primary'
                                    textColor='info'
                                    size='medium'
                                >Subscribe
                                </Button>
                            </Box>


                        </Paper>
                    </Grid>
                </Grid>

            </Box>

        </>
    )
}

export default SubscribtionSection
