import { Box, Paper, Typography, Grid, IconButton, Divider } from '@mui/material'
import React from 'react'
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
import yellowLOgo from './../assets/The-Viking-voyages-yellow-logo.png';
import { Link } from 'react-router-dom'

const Footer = () => {

    const manuList = [
        { key: '/about', value: 'About Voyages' },
        { key: '/contact', value: 'Contact with Voyeges' },
        { key: '/subscribe', value: 'Suscribe to Voyages' }
    ];

    const contant = manuList.map((index) => (

        <>
            <Link style={{ alignItems: "center", justifyContent: "center", minHeight: "1vh", color: "#FFFFFF", textDecoration: 'none', '&:hover': { color: 'secondary.main' } }} to={index.key}>
                <Typography variant="subtitle2" >
                    {index.value}
                </Typography>
            </Link>
            <Box mt={1} mb={1}>
                <Divider mt={3} sx={{ bgcolor: 'secondary.main' }} />
            </Box>
        </>


    ));

    return (
        <Box sx={{ minHeight: '20vh', bgcolor: 'primary.main', color: 'info.main', padding: 2 }}>
            <Box sx={{ ml: {  sm: 11, md: 15, lg: 19, xl: 23 }, mr: {  sm: 11, md: 15, lg: 19, xl: 23 } }}>
                <Grid container>
                    <Grid item xs={10} sm={6} >
                        <Typography variant='subtitle2' style={{ color: 'info', paddingTop: '4px' }}>
                            Follow us on
                        </Typography>
                        <Box >
                            <a>
                                <IconButton color="info" mt={2}>
                                    <FacebookOutlinedIcon fontSize='medium' />
                                </IconButton>
                            </a>
                            <a href='https://www.instagram.com/vikingvoyages' target="_blank">
                                <IconButton color="info" style={{ margin: '10px', color: 'info' }}>

                                    <InstagramIcon fontSize='medium' />

                                </IconButton>
                            </a>
                            <IconButton color="info" style={{ margin: '10px', color: 'info' }}>
                                <YouTubeIcon fontSize='medium' />
                            </IconButton>
                        </Box>
                    </Grid>

                </Grid>
            </Box>

            <Box sx={{ ml: {  sm: 3, md: 15, lg: 19, xl: 23 }, mr: {  sm: 3, md: 15, lg: 19, xl: 23 } }}>
                <Divider sx={{ bgcolor: 'secondary.main', padding: "2px" }} />
            </Box >

            <Box sx={{ ml: { sm: 11, md: 15, lg: 19, xl: 23 }, mr: {  sm: 11, md: 15, lg: 19, xl: 23 } }}>
                <Grid container>
                    <Grid item xs={10} sm={6} >
                        <Link to={'/'}>
                        <img
                            src={yellowLOgo}
                            alt={yellowLOgo}
                            style={{ width: '200px', height: '120px' }}
                        />
                    </Link>

                </Grid>
                <Grid item xs={10} sm={6} mt={2} sx={{ textAlign: 'center' }} >
                    {contant}
                    <Typography variant='subtitle2' pt={1} >
                        CopyrightⒸ2024
                    </Typography>

                </Grid>

            </Grid>
        </Box>
        </Box >
    )
}

export default Footer
