import { Box,Typography } from '@mui/material'
import React from 'react'

const HomeTitleSection = () => {
  return (
    <>
    <Box>
        <Typography variant='h6' mt={4} p={4} sx={{ textAlign: 'left',fontWeight:'bold', fontSize: { xs: '18px', sm: '18px', md: '23px' } }}>
            "Sweden, a Scandinavian nation known for its stunning natural landscapes, rich history, and progressive social polices, captivates visitors with its blend of modernity and tradition.
             Form the vabrant streets of Stockholm, the capital city, to the serene beauty of its archipelagos and forests, Sweden offers a deverse range of experiences."
        </Typography>
    </Box>
      
    </>
  )
}

export default HomeTitleSection
