import React from 'react'
import { Typography, Box, Grid, Divider, useTheme } from '@mui/material'
import QoutationIcon from './../assets/quote.png'
import AboutImage_01 from './../assets/about_01.jpeg'
import AboutImage_02 from './../assets/about_02.jpeg'
import AboutImage_03 from './../assets/about_03.jpeg'
import AboutImage_04 from './../assets/about_04.jpeg'
import SubSection from '../components/SubSection'
const About = () => {

  return (
    <>
      <Box sx={{ mt: { xs: 11, sm: 11, md: 19, lg: 19, xl: 21 }, ml: { xs: 2, sm: 3, md: 15, lg: 19, xl: 23 }, mr: { xs: 2, sm: 3, md: 15, lg: 19, xl: 23 } }}>
        <Box mt={3} sx={{ textAlign: 'center' }}>
          <Box>
            <Grid container /* alignItems={'center'} justifyContent={'center'} */ style={{ textAlign: 'center' }} spacing={5}>

              <Grid item xs={12} md={6} >
                <Box >
                  <Typography variant='h1' align='center' sx={{ fontFamily: 'Permanent Marker', fontSize: {  xs: '40px', md: '50px' }}}>
                    About Me
                  </Typography>
                </Box>
                <Box >
                  <Divider sx={{ bgcolor: 'secondary.main', padding: "2px" }} />
                </Box >
                <Box mt={3}>
                  <Typography variant='h3' sx={{ textAlign: 'left', fontWeight: 'bold', fontSize: {xs:'23px',  sm: '26px', md: '40px' } }} >
                    In 2010, I made my way to Sweden to pursue my Master of Science degree.
                  </Typography>
                  <br/>
                </Box>
                <Box mt={3}>
                  <Grid container style={{ textAlign: 'center' }}>
                    <Grid item xs={12} md={4}>
                      <img
                        src={QoutationIcon}
                        alt={QoutationIcon}
                        style={{ width: '50px', height: '30px' }}
                      />
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <Typography variant='body1' sx={{textAlign: 'left',fontSize: {  sm: '16px', md: '23px'  }}}>
                        When I first arrived in Sweden, I was completely clueless about what to expect.
                      </Typography>
                      <br/>
                      
                      <Typography variant='body1' mt={2} sx={{fontSize: {  sm: '16px', md: '23px'  }}}>
                        From navigating the complexities of mortgages to figuring out how to travel efficiently within Sweden, and from understanding the ins and outs
                        of the Metro system to obtaining my driver's license, I've had to learn everything the hard way.
                      </Typography>
                      <br/>
                      <Typography variant='body1' mt={2} sx={{textAlign: 'left',fontSize: {  sm: '16px', md: '23px'  }}}>
                        That's why I decided to create The Viking Voyages so that you don't have to go through the same struggles.
                        Here, I share insights and experiences from my journey in Sweden, offering tips and advice to make your transition smoother.
                        Whether you're a newcomer to Sweden or simply curious about life in this beautiful country, I invite you to join me on The Viking Voyages and explore all that
                        Sweden has to offer.
                      </Typography>
                      <Typography variant='body1' mt={2} sx={{fontSize: {  sm: '16px', md: '23px'  }}}>
                        Welcome to Sweden!
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>

              <Grid item xs={12} md={6}  sx={{  mt:{ xs:3, sm: 5, md:15}}}>
                <Box>
                <img
                        src={AboutImage_01}
                        alt={QoutationIcon}
                        style={{ width: '95%', height: 'auto' }}
                      />
                </Box>
                <Box mt={3}>
                  <Grid container spacing={1} >
                    <Grid item  xs={12} sm={4}>
                    <img
                        src={AboutImage_02}
                        alt={QoutationIcon}
                        style={{ width: '90%', height: '90%' }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4} sx={{textAlign:'center'}}>
                    <img
                        src={AboutImage_03}
                        alt={QoutationIcon}
                        style={{ width: '90%', height: '90%' }}
                      />
                    </Grid>
                    <Grid item xs={12}sm={4} md={4}>
                    <img
                        src={AboutImage_04}
                        alt={QoutationIcon}
                        style={{ width: '90%', height: '90%' }}
                      />
                    </Grid>

                  </Grid>

                </Box>
              
              </Grid>

            </Grid>

          </Box>
        </Box>
        <Box mt={4} mb={3} p={3} sx={{backgroundColor:'info.dark'}}>
          <SubSection/>
        </Box>
      </Box >





    </>
  )
}

export default About
